.chat-gpt-panel {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  width: 300px;
  background: white;
  border-left: 1px solid #ccc;
  padding: 20px;
  overflow-y: auto;
  z-index: 100;
}

.chat-gpt-panel button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.chat-gpt-panel input {
  margin-bottom: 10px;
}

.chat-gpt-panel select {
  margin-bottom: 10px;
}

.chapter-summary {
  margin-top: 20px;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 5px;
}

.chapter-summary h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #333;
}

.chapter-summary ul {
  margin-left: 20px;
}

.cross-reference {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.question-section {
  margin: 20px 0;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 5px;
}

.question-section input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error {
  color: #dc3545;
  margin: 10px 0;
  padding: 10px;
  background: #f8d7da;
  border-radius: 4px;
}

.select-all-container {
  margin: 10px 0;
  padding: 5px;
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-all-container input[type="checkbox"] {
  margin: 0;
}

.select-all-container label {
  margin: 0;
  cursor: pointer;
}

.response-length-selector {
  margin-bottom: 15px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
}

.response-length-selector label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.response-length-selector select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  position: relative;
}

.button-group button:not(.help-button) {
  flex: 1;
}

.help-button {
  width: 24px !important;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #6c757d;
  font-size: 14px;
  cursor: pointer;
}

.help-button:hover {
  background: #e9ecef;
}

.tooltip {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  background: #495057;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
  z-index: 1000;
}

.tooltip::after {
  content: '';
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 6px 0 6px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #495057;
}

.bible-reader-container {
  transition: margin-right 0.3s ease;
}

.bible-reader-container.panel-open {
  margin-right: 300px;
}
