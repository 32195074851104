.reference {
  cursor: pointer;
  color: blue;
  font-size: 0.7em;
  vertical-align: super;
}

.footnote {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.select-all-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 10px 0;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
}

.inverse-button {
  padding: 5px 10px;
  background: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}

.inverse-button:hover {
  background: #dee2e6;
}

.bible-reader-container {
  padding-top: 20px;
  transition: margin-right 0.3s ease;
}

.bible-reader-container.panel-open {
  margin-right: 300px;
}

.bible-header {
  position: sticky;
  top: 0;
  background: white;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 150;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.bible-content {
  padding: 20px;
}

.gpt-toggle-button {
  padding: 8px 16px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.gpt-toggle-button:hover {
  background: #e0e0e0;
}
