.tableOfContents {
  margin: 2rem auto;
  max-width: 1000px;
  padding: 2rem;
  background-color: var(--background-color);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: left;
  margin-bottom: 2rem;
  color: var(--primary-color);
  font-size: 2.5rem;
  padding-left: 1rem; /* Align with the start of the border */
}

.testaments {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.testament {
  flex: 1;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.testamentTitle {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--primary-color);
  font-size: 1.8rem;
}

.bookList {
  list-style-type: none;
  padding: 0;
  columns: 2;
  column-gap: 2rem;
}

.bookItem {
  margin-bottom: 0.5rem;
  break-inside: avoid-column;
  padding-left: 0.5rem; /* Move books slightly to the right */
}

.bookLink {
  text-decoration: none;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.bookLink:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .testaments {
    flex-direction: column;
  }

  .bookList {
    columns: 1;
  }
}
