.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 1rem 0;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo a {
  color: var(--background-color);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.nav li {
  margin-right: 1rem;
}

.nav a {
  color: var(--background-color);
  text-decoration: none;
}

.userModule {
  display: flex;
  align-items: center;
}

.loginButton,
.profileButton {
  background-color: var(--background-color);
  color: var(--primary-color);
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
}

.main {
  flex-grow: 1;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.footer {
  background-color: var(--primary-color);
  color: var(--background-color);
  text-align: center;
  padding: 1rem 0;
}

@media (max-width: 1240px) {
  .headerContent,
  .main {
    max-width: 95%;
  }
}

@media (max-width: 768px) {
  .headerContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav,
  .userModule {
    margin-top: 1rem;
  }
}
